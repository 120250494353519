<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">List Karyakartas</div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="voter-all-details-tab" data-bs-toggle="tab" data-bs-target="#voteralldetail"
                type="button" role="tab" aria-controls="vehicle-details" aria-selected="true">
                Karyakartas Details
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-report-tab" data-bs-toggle="tab" data-bs-target="#voterreport" type="button"
                role="tab" aria-controls="customer-details" aria-selected="false" @click="redirecttoreport">
                Reports
            </button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" id="voterreport" role="tabpanel" aria-labelledby="voter-report-tab"></div>
        <div class="tab-pane fade show active" id="voteralldetail" role="tabpanel" aria-labelledby="voter-all-details-tab">
            <div class="company-section-outer">
                <div class="layout-content-section">
                    <div class="custom-ultima-datatable custom-voter-datable custom-header-none"
                        style="height: calc(100vh - 180px)">
                        <DataTable :value="messageList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
                            :paginator="true" :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            responsiveLayout="scroll"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :lazy="true"
                            dataKey="lk1" :loading="loading">
                            <template #empty>No Records found.</template>
                            <template #loading>Loading data. Please wait...</template>
                            <Column headerStyle="width: 100%" bodyStyle="width: 100%">
                                <template #body="{ data }">
                                    <div class="company-detail-box-outer mb-3">
                                        <div class="row g-2">
                                            <div class="
                                                        col-lg-2 col-md-2 col-12
                                                        voter-profile-col
                                                        d-flex
                                                        align-items-center
                                                      ">
                                                <div class="customer-label-group">
                                                    <div class="d-flex align-items-center">
                                                        <div>
                                                            <img v-if="data.lk28 == null || data.lk28 == ''"
                                                                src="/assets/images/avatar.png" class="
                                                                rounded
                                                                bg-light
                                                                me-auto
                                                                d-block
                                                                voter-photo-img
                                                              " alt="" />
                                                            <img v-if="data.lk28 != null && data.lk28 != ''"
                                                                :src="data.lk28" class="
                                                                rounded
                                                                bg-light
                                                                me-auto
                                                                d-block
                                                                voter-photo-img
                                                              " alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-12 voter-name-static-col">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Name</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{ data.lk17 ? data.lk17 : "N/A" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group">
                                                            <label class="form-label">Designation</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{ data.lk55 ? data.lk55 : "N/A" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Date of Birth | Age</label>
                                                            <div class="from-label-value">
                                                                {{ data.lk21 ? data.lk21 : "N/A" }} |
                                                                {{ data.lk22 ? data.lk22 : "N/A" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group">
                                                            <label class="form-label">Booth | Office | Pin Code</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{ data.lk27 ? data.lk27 : "N/A" }} | {{ data.lk19 ?
                                                                    data.lk19 : "N/A" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Reg. Date & Time</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{ format_date(data.lk2) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group">
                                                            <label class="form-label">Profession</label>
                                                            <div class="from-label-value text-capitalize">
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Email</label>
                                                            <div class="from-label-value">
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="
                                                              customer-label-group
                                                              d-flex
                                                              justify-content-between
                                                            ">
                                                            <div>
                                                                <label class="form-label">Mobile</label>
                                                                <div class="from-label-value">
                                                                    +{{ data.mobnumber }}
                                                                    <!-- +91-{{ data.first3digit }}XXXX{{
                                                                        data.last3digit
                                                                    }} -->
                                                                </div>
                                                            </div>
                                                            <div class="d-flex align-items-end">
                                                                <button class="btn custom-outline-call-btn mx-2"
                                                                    @click="calltriggertovoter(data.lk3)"
                                                                    :disabled="callstatus == 1">
                                                                    <i class="fas fa-phone-alt call-btn-color"></i></button>
                                                                <!-- <button class="btn custom-outline-whatsapp-btn me-2">
                                                                    <i class="fab fa-whatsapp whatsapp-color"></i>
                                                                </button> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from "moment";
export default {
    data() {
        return {
            messageList: [],
            totalRecords: 0,
            page_no: 0,
            loading: false,
            callstatus: 0,
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        // this.timer = window.setInterval(() => {
        this.getmeranetawhatsappmsgs({
            page_no: this.page_no,
            lk17: this.lk17,
            lk3: this.lk3,
            lk23: this.lk23,
            lk25: this.lk25,
            lk20: this.lk20,
            lk24: this.lk24,
            lk22: this.lk22,
            lk19: this.lk19,
            lk18: this.lk18,
            lk26: this.lk26,
        });
        // }, 1000);
    },
    beforeUnmount() {
        clearInterval(this.timer);
    },
    methods: {
        getmeranetawhatsappmsgs(e) {
            this.ApiService.getmeranetawhatsappmsgs(e).then((data) => {
                if (data.success == true) {
                    this.messageList = data.records;
                    this.totalRecords = data.totalcount;
                    this.loading = false;
                } else {
                    this.messageList = null;
                    this.totalRecords = 0;
                    this.loading = false;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getmeranetawhatsappmsgs({ page_no: this.page_no });
        },
        calltriggertovoter(ev) {
            this.callstatus = 1;
            this.ApiService.triggercalltomeraneta({ mobile_number: ev }).then(
                (items) => {
                    if (items.success === true) {
                        this.callstatus = 0;
                    } else {
                        this.$toast.open({
                            message: items.message,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.callstatus = 0;
                    }
                }
            );
        },
        redirecttoreport() {
            this.$router.push("/karyakartas/report");
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY hh:mm A");
            }
        },
    },
};
</script>
<style scoped>
.company-detail-box-outer {
    padding: 16px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    word-break: break-word;
}

.voter-name-static-col {
    width: 24.32%;
}
</style>